import toFinite from 'lodash/toFinite';

import { BOOKING_TYPES } from '@/constants/bookingTypes';

// NOTE: Not CS Operator config are listed
export const CS_OPERATOR_CONFIG = {
  allowAdditionalDriver: 'allow_additional_driver',
  allowPassengers: 'allow_passengers',
  hasOneWay: 'has_one_way',
  badgeRequired: 'badge_required',
  chatbotAppId: 'chatbot_app_id',
  intervalTimeUnit: 'interval_time_unit',
  minutesToBookBeforeCurrentTime: 'minutes_to_book_before_current_time',
  carsharingDefaultDuration: 'carsharing_default_duration',
  csMinimumBookingDuration: 'cs_minimum_booking_duration',
  defaultCsMinimumBookingDuration: 'default_cs_minimum_booking_duration',
  defaultCsMaximumBookingDuration: 'default_cs_maximum_booking_duration',
  csMaximumBookingDuration: 'cs_maximum_booking_duration',
  csBookingGap: 'cs_booking_gap',
  ldMinimumBookingDuration: 'ld_minimum_booking_duration',
  defaultLdMinimumBookingDuration: 'default_ld_minimum_booking_duration',
  ldMaximumBookingDuration: 'ld_maximum_booking_duration',
  defaultLdMaximumBookingDuration: 'default_ld_maximum_booking_duration',
  ldBookingGap: 'ld_booking_gap',
  longDistanceDefaultDuration: 'long_distance_default_duration',
  interventionDefaultDuration: 'intervention_default_duration',
  inMinimumBookingDuration: 'in_minimum_booking_duration',
  defaultInMinimumBookingDuration: 'default_in_minimum_booking_duration',
  defaultInMaximumBookingDuration: 'default_in_maximum_booking_duration',
  inMaximumBookingDuration: 'in_maximum_booking_duration',
  inBookingGap: 'in_booking_gap',
  csMaximumFutureBookingTime: 'cs_maximum_future_booking_time',
  ldMaximumFutureBookingTime: 'ld_maximum_future_booking_time',
  inMaximumFutureBookingTime: 'in_maximum_future_booking_time',
  maximumRecurringBookings: 'max_recurring_bookings',
};

export const CS_OPERATOR_FIELD_TYPE_TRANSFORMERS = {
  [CS_OPERATOR_CONFIG.allowAdditionalDriver]: Boolean,
  [CS_OPERATOR_CONFIG.allowPassengers]: Boolean,
  [CS_OPERATOR_CONFIG.hasOneWay]: Boolean,
  [CS_OPERATOR_CONFIG.badgeRequired]: Boolean,
  [CS_OPERATOR_CONFIG.intervalTimeUnit]: toFinite,
  [CS_OPERATOR_CONFIG.minutesToBookBeforeCurrentTime]: toFinite,
  [CS_OPERATOR_CONFIG.carsharingDefaultDuration]: toFinite,
  [CS_OPERATOR_CONFIG.csMinimumBookingDuration]: toFinite,
  [CS_OPERATOR_CONFIG.defaultCsMinimumBookingDuration]: toFinite,
  [CS_OPERATOR_CONFIG.defaultCsMaximumBookingDuration]: toFinite,
  [CS_OPERATOR_CONFIG.csMaximumBookingDuration]: toFinite,
  [CS_OPERATOR_CONFIG.csBookingGap]: toFinite,
  [CS_OPERATOR_CONFIG.ldMinimumBookingDuration]: toFinite,
  [CS_OPERATOR_CONFIG.defaultLdMinimumBookingDuration]: toFinite,
  [CS_OPERATOR_CONFIG.ldMaximumBookingDuration]: toFinite,
  [CS_OPERATOR_CONFIG.defaultLdMaximumBookingDuration]: toFinite,
  [CS_OPERATOR_CONFIG.ldBookingGap]: toFinite,
  [CS_OPERATOR_CONFIG.longDistanceDefaultDuration]: toFinite,
  [CS_OPERATOR_CONFIG.interventionDefaultDuration]: toFinite,
  [CS_OPERATOR_CONFIG.inMinimumBookingDuration]: toFinite,
  [CS_OPERATOR_CONFIG.defaultInMinimumBookingDuration]: toFinite,
  [CS_OPERATOR_CONFIG.defaultInMaximumBookingDuration]: toFinite,
  [CS_OPERATOR_CONFIG.inMaximumBookingDuration]: toFinite,
  [CS_OPERATOR_CONFIG.inBookingGap]: toFinite,
  [CS_OPERATOR_CONFIG.csMaximumFutureBookingTime]: toFinite,
  [CS_OPERATOR_CONFIG.ldMaximumFutureBookingTime]: toFinite,
  [CS_OPERATOR_CONFIG.inMaximumFutureBookingTime]: toFinite,
};

export const CS_OPERATOR_COMMON_KEYS = [
  CS_OPERATOR_CONFIG.intervalTimeUnit,
  CS_OPERATOR_CONFIG.minutesToBookBeforeCurrentTime,
];

export const CS_OPERATOR_KEYS_BY_BOOKING_TYPE = {
  [BOOKING_TYPES.carsharing]: [
    CS_OPERATOR_CONFIG.carsharingDefaultDuration,
    CS_OPERATOR_CONFIG.csMinimumBookingDuration,
    CS_OPERATOR_CONFIG.defaultCsMinimumBookingDuration,
    CS_OPERATOR_CONFIG.defaultCsMaximumBookingDuration,
    CS_OPERATOR_CONFIG.csMaximumBookingDuration,
    CS_OPERATOR_CONFIG.csBookingGap,
    CS_OPERATOR_CONFIG.csMaximumFutureBookingTime,
  ],
  [BOOKING_TYPES.longDistance]: [
    CS_OPERATOR_CONFIG.ldMinimumBookingDuration,
    CS_OPERATOR_CONFIG.defaultLdMinimumBookingDuration,
    CS_OPERATOR_CONFIG.ldMaximumBookingDuration,
    CS_OPERATOR_CONFIG.defaultLdMaximumBookingDuration,
    CS_OPERATOR_CONFIG.ldBookingGap,
    CS_OPERATOR_CONFIG.longDistanceDefaultDuration,
    CS_OPERATOR_CONFIG.ldMaximumFutureBookingTime,
  ],
  [BOOKING_TYPES.intervention]: [
    CS_OPERATOR_CONFIG.interventionDefaultDuration,
    CS_OPERATOR_CONFIG.inMinimumBookingDuration,
    CS_OPERATOR_CONFIG.defaultInMinimumBookingDuration,
    CS_OPERATOR_CONFIG.defaultInMaximumBookingDuration,
    CS_OPERATOR_CONFIG.inMaximumBookingDuration,
    CS_OPERATOR_CONFIG.inBookingGap,
    CS_OPERATOR_CONFIG.inMaximumFutureBookingTime,
  ],
};

export const CS_OPERATOR_DEFAULT_VALUES = {
  [CS_OPERATOR_CONFIG.allowAdditionalDriver]: false,
  [CS_OPERATOR_CONFIG.allowPassengers]: false,
  [CS_OPERATOR_CONFIG.hasOneWay]: false,
  [CS_OPERATOR_CONFIG.badgeRequired]: false,
  [CS_OPERATOR_CONFIG.intervalTimeUnit]: 15,
  [CS_OPERATOR_CONFIG.minutesToBookBeforeCurrentTime]: 0,
  [CS_OPERATOR_CONFIG.carsharingDefaultDuration]: 60,
  [CS_OPERATOR_CONFIG.csMinimumBookingDuration]: 60,
  [CS_OPERATOR_CONFIG.defaultCsMinimumBookingDuration]: 60,
  [CS_OPERATOR_CONFIG.defaultCsMaximumBookingDuration]: 3600,
  [CS_OPERATOR_CONFIG.csMaximumBookingDuration]: 3600,
  [CS_OPERATOR_CONFIG.csBookingGap]: 15,
  [CS_OPERATOR_CONFIG.ldMinimumBookingDuration]: 1440,
  [CS_OPERATOR_CONFIG.defaultLdMinimumBookingDuration]: 1440,
  [CS_OPERATOR_CONFIG.ldMaximumBookingDuration]: 21600,
  [CS_OPERATOR_CONFIG.defaultLdMaximumBookingDuration]: 21600,
  [CS_OPERATOR_CONFIG.ldBookingGap]: 15,
  [CS_OPERATOR_CONFIG.longDistanceDefaultDuration]: 1440,
  [CS_OPERATOR_CONFIG.interventionDefaultDuration]: 525600,
  [CS_OPERATOR_CONFIG.inMinimumBookingDuration]: 525600,
  [CS_OPERATOR_CONFIG.defaultInMinimumBookingDuration]: 525600,
  [CS_OPERATOR_CONFIG.defaultInMaximumBookingDuration]: 525600,
  [CS_OPERATOR_CONFIG.inMaximumBookingDuration]: 525600,
  [CS_OPERATOR_CONFIG.inBookingGap]: 15,
  [CS_OPERATOR_CONFIG.csMaximumFutureBookingTime]: 43200,
  [CS_OPERATOR_CONFIG.ldMaximumFutureBookingTime]: 43200,
  [CS_OPERATOR_CONFIG.inMaximumFutureBookingTime]: 43200,
};

export const CS_OPERATOR_TIME_CONFIG_KEY = {
  bookingGap: 'bookingGap',
  defaultDuration: 'defaultDuration',
  minimumBookingDuration: 'minimumBookingDuration',
  maximumBookingDuration: 'maximumBookingDuration',
  defaultMinimumBookingDuration: 'defaultMinimumBookingDuration',
  defaultMaximumBookingDuration: 'defaultMaximumBookingDuration',
  maximumFutureBookingTime: 'maximumFutureBookingTime',
};

export const CS_OPERATOR_TIME_CONFIG_KEYS_REDUCER = {
  [CS_OPERATOR_CONFIG.carsharingDefaultDuration]: CS_OPERATOR_TIME_CONFIG_KEY.defaultDuration,
  [CS_OPERATOR_CONFIG.csMinimumBookingDuration]: CS_OPERATOR_TIME_CONFIG_KEY.minimumBookingDuration,
  [CS_OPERATOR_CONFIG.csMaximumBookingDuration]: CS_OPERATOR_TIME_CONFIG_KEY.maximumBookingDuration,
  [CS_OPERATOR_CONFIG.defaultCsMinimumBookingDuration]: CS_OPERATOR_TIME_CONFIG_KEY.defaultMinimumBookingDuration,
  [CS_OPERATOR_CONFIG.defaultCsMaximumBookingDuration]: CS_OPERATOR_TIME_CONFIG_KEY.defaultMaximumBookingDuration,
  [CS_OPERATOR_CONFIG.csBookingGap]: CS_OPERATOR_TIME_CONFIG_KEY.bookingGap,
  [CS_OPERATOR_CONFIG.longDistanceDefaultDuration]: CS_OPERATOR_TIME_CONFIG_KEY.defaultDuration,
  [CS_OPERATOR_CONFIG.ldMinimumBookingDuration]: CS_OPERATOR_TIME_CONFIG_KEY.minimumBookingDuration,
  [CS_OPERATOR_CONFIG.defaultLdMinimumBookingDuration]: CS_OPERATOR_TIME_CONFIG_KEY.defaultMinimumBookingDuration,
  [CS_OPERATOR_CONFIG.ldMaximumBookingDuration]: CS_OPERATOR_TIME_CONFIG_KEY.maximumBookingDuration,
  [CS_OPERATOR_CONFIG.defaultLdMaximumBookingDuration]: CS_OPERATOR_TIME_CONFIG_KEY.defaultMaximumBookingDuration,
  [CS_OPERATOR_CONFIG.ldBookingGap]: CS_OPERATOR_TIME_CONFIG_KEY.bookingGap,
  [CS_OPERATOR_CONFIG.interventionDefaultDuration]: CS_OPERATOR_TIME_CONFIG_KEY.defaultDuration,
  [CS_OPERATOR_CONFIG.inMinimumBookingDuration]: CS_OPERATOR_TIME_CONFIG_KEY.minimumBookingDuration,
  [CS_OPERATOR_CONFIG.defaultInMinimumBookingDuration]: CS_OPERATOR_TIME_CONFIG_KEY.defaultMinimumBookingDuration,
  [CS_OPERATOR_CONFIG.defaultInMaximumBookingDuration]: CS_OPERATOR_TIME_CONFIG_KEY.defaultMaximumBookingDuration,
  [CS_OPERATOR_CONFIG.inMaximumBookingDuration]: CS_OPERATOR_TIME_CONFIG_KEY.maximumBookingDuration,
  [CS_OPERATOR_CONFIG.inBookingGap]: CS_OPERATOR_TIME_CONFIG_KEY.bookingGap,
  [CS_OPERATOR_CONFIG.csMaximumFutureBookingTime]: CS_OPERATOR_TIME_CONFIG_KEY.maximumFutureBookingTime,
  [CS_OPERATOR_CONFIG.ldMaximumFutureBookingTime]: CS_OPERATOR_TIME_CONFIG_KEY.maximumFutureBookingTime,
  [CS_OPERATOR_CONFIG.inMaximumFutureBookingTime]: CS_OPERATOR_TIME_CONFIG_KEY.maximumFutureBookingTime,
};
