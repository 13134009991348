<template>
  <div class="ListPaymentMethods">
    <ui-loader
      v-if="isLoading"
      absolute
    />

    <template v-else>
      <EmptyStateComponent
        v-if="isEmpty(paymentMethodsCollection)"
        has-background
        :src="emptyPaymentMethodsSrc"
        :title="$t('payments.payment_methods.no_payment')"
        :description="$t('payments.payment_methods.no_payment_description')"
        :action="{
          text: $t('common.payment_method.add_new_method'),
          callback: () => $emit('launch-add-payment-method'),
          dataTestId: 'add_new_payment_method-button',
        }"
        data-test-id="empty-list"
      />
      <ul class="w-50">
        <li
          v-for="(paymentMethod, index) in paymentMethodsDisplayed"
          :key="index"
          class="ListPaymentMethods__row emobg-border-color-ground"
        >
          <ListPaymentMethodsItem
            :index="index"
            :is-loading="isLoading"
            :payment-method="paymentMethod"
            :default-payment-method-key="defaultPaymentMethodKey"
            :default-payment-method-uuid="defaultPaymentMethodUuid"
            :default-opposite-payment-method-uuid="defaultOppositePaymentMethodUuid"
            :is-open="getOpenDetails(index)"
            @on:open-payment-method-detail="onOpenDetails"
            @on:change-payment-method-default="updateDefaultPaymentMethod"
            @delete:payment-list-item="onDeletePaymentMethod"
          />
        </li>
      </ul>
      <ui-button
        v-if="!isEmpty(paymentMethodsDisplayed)"
        v-bind="fetchButtonSpecs()"
        class="d-block my-6"
        data-test-id="add_new_payment_method-button"
        @clickbutton="$emit('launch-add-payment-method')"
      >
        {{ $t('common.payment_method.add_method') }}
      </ui-button>
      <ui-modal
        :open="isDeleteModalOpen"
        :header="$t('modal.payment_method.remove_card_title')"
      >
        <div slot="body">
          <p>
            {{ $t('modal.payment_method.remove_card_description', { paymentMethodNumber: deletingPaymentMethodNumber }) }}
          </p>
        </div>
        <div slot="footer">
          <div class="d-flex justify-content-end">
            <ui-button
              v-bind="fetchButtonSpecs({ buttonType: THEME_BUTTON_TYPES.SECONDARY })"
              @clickbutton="cancelDeletePaymentMethod"
            >
              {{ $t('buttons.cancel') }}
            </ui-button>
            <ui-button
              v-bind="fetchButtonSpecs()"
              :loading="isDeleting"
              class="ml-3"
              @clickbutton="emitDeleteEvent"
            >
              {{ $t('buttons.confirm') }}
            </ui-button>
          </div>
        </div>
      </ui-modal>
    </template>
  </div>
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import find from 'lodash/find';
import cloneDeep from 'lodash/cloneDeep';
import includes from 'lodash/includes';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';

import { mapGetters, mapState } from 'vuex';

import EmptyStateComponent from '@/components/EmptyState/EmptyStateComponent';
import { emptyPaymentMethods } from '@/utils/publicImages';
import each from 'lodash/each';
import ListPaymentMethodsItem from '@/domains/Shared/Payments/PaymentMethodsHub/ListPaymentMethodsItem';
import { NOTIFICATION_MESSAGE_TYPES } from '@/domains/Shared/Payments/PaymentMethodsHub/constants/paymentMethodsHub';
import { FALLBACK_MESSAGE } from '@emobg/web-utils';
import { useTheme } from '@/composable/Theme/useTheme';

import { nameSpace as PaymentMethodsHubNameSpace } from './stores/PaymentMethodsHub';
import { paymentMethodIcon } from './PaymentMethodsUtils';

export default {
  name: 'ListPaymentMethods',

  components: {
    ListPaymentMethodsItem,
    EmptyStateComponent,
  },

  props: {
    profile: {
      type: Object,
      required: true,
    },

    isLoading: {
      type: Boolean,
      default: false,
    },

    isDeleting: {
      type: Boolean,
      default: false,
    },
    isDeleteModalOpen: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { fetchButtonSpecs } = useTheme();
    return {
      isEmpty,
      paymentMethodIcon,
      fetchButtonSpecs,
    };
  },
  data() {
    return {
      defaultPaymentMethodUuid: '',
      defaultOppositePaymentMethodUuid: '',
      defaultPaymentMethodKey: 0,
      paymentMethodDetailManager: [],
      deletingPaymentMethod: null,
      copyTag: '-copy',
    };
  },

  computed: {
    ...mapState(PaymentMethodsHubNameSpace, [
      'paymentMethodsCollectionB2B',
      'paymentMethodsCollectionB2C',
    ]),

    ...mapGetters(PaymentMethodsHubNameSpace, [
      'getDefaultPaymentMethodUUID',
      'getDefaultOppositePaymentMethodUUID',
    ]),

    paymentMethodsCollection() {
      return this?.profile?.isBusinessProfile
        ? this.paymentMethodsCollectionB2B
        : this.paymentMethodsCollectionB2C;
    },
    deletingPaymentMethodNumber() {
      return this.deletingPaymentMethod ? this.deletingPaymentMethod.number : FALLBACK_MESSAGE.dash;
    },
    paymentMethodsDisplayed() {
      const list = cloneDeep(this.paymentMethodsCollection);
      const oppositePayment = find(list, ['uuid', this.defaultOppositePaymentMethodUuid]);
      if (oppositePayment && !this.hasSameDefault) {
        const oppositePaymentCopy = cloneDeep(oppositePayment);
        oppositePaymentCopy.uuid = `${this.defaultOppositePaymentMethodUuid}${this.copyTag}`;
        oppositePayment.isOpposite = true;
        list.push(oppositePaymentCopy);
      }
      return this.sortMethodPayments(list);
    },
    hasSameDefault() {
      return this.defaultPaymentMethodUuid === this.defaultOppositePaymentMethodUuid;
    },
  },

  watch: {
    paymentMethodsCollection: {
      async handler() {
        try {
          this.defaultPaymentMethodUuid = await this.getDefaultPaymentMethodUUID(this.profile);
          this.defaultOppositePaymentMethodUuid = await this.getDefaultOppositePaymentMethodUUID(this.profile);

          this.forceRender();
        } catch (error) {
          this.defaultPaymentMethodUuid = '';
        }
      },
      deep: true,
      immediate: true,
    },
  },

  async created() {
    this.emptyPaymentMethodsSrc = emptyPaymentMethods;
    this.defaultOppositePaymentMethodUuid = await this.getDefaultOppositePaymentMethodUUID(this.profile);
    each(this.paymentMethodsDisplayed, payment => {
      this.paymentMethodDetailManager.push({
        uuid: payment.uuid,
        isOpen: false,
      });
    });
  },
  methods: {
    forceRender() {
      this.defaultPaymentMethodKey += 1;
    },
    sortMethodPayments(paymentList) {
      each(paymentList, (item) => {
        const opposite = find(paymentList, ['isOpposite', true]);
        item.order = 2;
        if (opposite) {
          if (item.uuid === this.defaultPaymentMethodUuid) {
            item.isDefault = true;
          }
        } else if (item.uuid === this.defaultOppositePaymentMethodUuid) {
          item.isOpposite = true;
        } else if (item.uuid === this.defaultPaymentMethodUuid) {
          item.isDefault = true;
        }
        item.order = item.isDefault ? 1 : 2;
        item.order = item.isOpposite ? 0 : 2;
      });
      return sortBy(paymentList, ['order']);
    },
    onOpenDetails(index) {
      this.paymentMethodDetailManager[index].isOpen = !this.paymentMethodDetailManager[index].isOpen;
    },
    onDeletePaymentMethod(onDeletePayload) {
      this.deletingPaymentMethod = this.paymentMethodsDisplayed[onDeletePayload.index];
      if (onDeletePayload.isDefault || includes(this.deletingPaymentMethod.uuid, this.copyTag)) {
        if (size(this.paymentMethodsCollection) > 1) {
          this.$emit('update:deleting-default-single-payment-method', {
            message: this.$t('payments.payment_methods.notifications.delete_payment_method.error.user_has_single_payment_method'),
            type: NOTIFICATION_MESSAGE_TYPES.error,
          });
        } else {
          this.$emit('update:deleting-default-multiple-payment-method', {
            message: this.$t('payments.payment_methods.notifications.delete_payment_method.error.user_has_multiple_payment_method'),
            type: NOTIFICATION_MESSAGE_TYPES.error,
          });
        }
      } else {
        this.$emit('update:delete-modal-open');
      }
    },
    emitDeleteEvent() {
      this.$emit('delete:payment-method', this.deletingPaymentMethod.uuid);
      this.deletingPaymentMethod = null;
    },
    cancelDeletePaymentMethod() {
      this.$emit('update:delete-modal-close');
    },
    updateDefaultPaymentMethod(index) {
      if (includes(this.paymentMethodsDisplayed[index].uuid, this.copyTag)) {
        this.$emit('update:set-default-payment-method', this.defaultOppositePaymentMethodUuid);
      } else {
        this.$emit('update:set-default-payment-method', this.paymentMethodsDisplayed[index].uuid);
      }
    },
    getOpenDetails(index) {
      return get(this, `paymentMethodDetailManager[${index}].isOpen`, false);
    },
  },
};
</script>

<style lang="scss">

li.ListPaymentMethods__row {
  border-top: 1px solid;

  &:last-child {
    border-bottom: 1px solid;
  }
}
</style>
