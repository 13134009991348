import { storage } from '../storage';

import { ACTIONS, ACTION_USED, STAGE } from '../../constants/paymentMethodsHub';

import { matchCurrentAction } from './actionHelper';

export const onRefused = composablePayload => {
  const {
    originalResponse,
    parentActionCallback,
    contextData,
    commonEmit,
  } = composablePayload;

  const message = originalResponse.providerMessage;
  const canRetry = originalResponse.retry;

  let emittedResponse = {
    refusedMessage: message,
    canRetry,
  };

  parentActionCallback('setStage', STAGE.refused);

  const actionUsed = storage.get(ACTION_USED);

  const { currentAction } = contextData.flowTracking;

  const { isActionAdd, isActionList, isActionPayment } = matchCurrentAction(currentAction);

  const isAddInRedirected = contextData.isRedirected && actionUsed === ACTIONS.add;

  if (isAddInRedirected) {
    emittedResponse = {
      refusedMessage: message,
    };
  }

  if (isActionAdd) {
    parentActionCallback('onAddPaymentMethodError', message);
  }

  if (isAddInRedirected || isActionList) {
    commonEmit('on:add-payment-method-refused', emittedResponse);
  }

  parentActionCallback('toggleHelperComponentLoader', false);

  parentActionCallback('resetRedirect');

  if (isActionPayment) {
    parentActionCallback('trackInternalPayment', originalResponse);
    parentActionCallback('saveResponse', originalResponse);
  }
};
