import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import defaults from 'lodash/defaults';
import mapKeys from 'lodash/mapKeys';
import values from 'lodash/values';
import includes from 'lodash/includes';
import mapValues from 'lodash/mapValues';
import isFunction from 'lodash/isFunction';
import camelCase from 'lodash/camelCase';
import map from 'lodash/map';
import concat from 'lodash/concat';

import {
  LOG_TYPE, camelCaseKeys, logger, snakeCaseKeys,
} from '@emobg/web-utils';
import {
  CS_OPERATOR_COMMON_KEYS, CS_OPERATOR_CONFIG, CS_OPERATOR_DEFAULT_VALUES, CS_OPERATOR_FIELD_TYPE_TRANSFORMERS,
  CS_OPERATOR_KEYS_BY_BOOKING_TYPE, CS_OPERATOR_TIME_CONFIG_KEYS_REDUCER,
} from '@/constants/csOperatorConfig';
import { BOOKING_TYPES } from '@/constants/bookingTypes';

/**
 * @desc It returns cs operator config for allow additional driver or default value
 *
 * @param {object} csOperatorConfig cs operator config values
 * @example <caption>Example usage of allowsAdditionalDrivers.</caption>
 * // returns "true"
 * allowsAdditionalDrivers({ allow_additional_driver: true, ... })
 * @returns {boolean}
 */
export const allowsAdditionalDrivers = csOperatorConfig => (isEmpty(csOperatorConfig)
  ? false
  : Boolean(get(csOperatorConfig, CS_OPERATOR_CONFIG.allowAdditionalDriver)));

/**
 * @desc It returns cs operator config for allow passengers or default value
 *
 * @param {object} csOperatorConfig cs operator config values
 * @example <caption>Example usage of allowsPassengers.</caption>
 * // returns "true"
 * allowsPassengers({ allow_passengers: true, ... })
 * @returns {boolean}
 */
export const allowsPassengers = csOperatorConfig => (isEmpty(csOperatorConfig)
  ? false
  : Boolean(get(csOperatorConfig, CS_OPERATOR_CONFIG.allowPassengers)));

/**
 * @desc It returns cs operator config for allow one way booking trip type or default value
 *
 * @param {object} csOperatorConfig cs operator config values
 * @example <caption>Example usage of allowsOneWay.</caption>
 * // returns "true"
 * allowsOneWay({ has_one_way: true, ... })
 * @returns {boolean}
 */
export const allowsOneWay = csOperatorConfig => (isEmpty(csOperatorConfig)
  ? false
  : Boolean(get(csOperatorConfig, CS_OPERATOR_CONFIG.hasOneWay)));

/**
 * @desc It returns cs operator config with default values and keys camel cased
 *
 * @param {object} csOperatorConfig cs operator config values
 * @example <caption>Example usage of sanitizeCsOperatorConfigs.</caption>
 * // returns { allowAdditionalDriver: true, allowPassengers: false,  hasOneWay: false, ... }
 * sanitizeCsOperatorConfigs({ allow_additional_driver: true, allow_passengers: null,  has_one_way: undefined, ... })
 * @returns {object}
 */
export const sanitizeCsOperatorConfigs = csOperatorConfig => {
  if (isEmpty(csOperatorConfig)) {
    const errorMessage = 'csOperatorConfig can not be empty';
    logger.message(errorMessage, LOG_TYPE.error);
    throw new Error(errorMessage);
  }

  const requiredCsOperatorConfig = pick(csOperatorConfig, values(CS_OPERATOR_CONFIG));
  const sanitizedValues = mapValues(requiredCsOperatorConfig, (configValue, configKey) => {
    const sanitizerMethod = get(CS_OPERATOR_FIELD_TYPE_TRANSFORMERS, configKey);
    return isFunction(sanitizerMethod)
      ? sanitizerMethod(configValue)
      : configValue;
  });

  return camelCaseKeys(defaults(sanitizedValues, CS_OPERATOR_DEFAULT_VALUES));
};

/**
 * @desc It returns cs operator config with default values and keys camel cased for time related configs
 *
 * @param {object} csOperatorConfig cs operator config values
 * @param {string} bookingType booking type string (carsharing / long distance / intervention)
 * @example <caption>Example usage of bookingRulesOperatorConfigs.</caption>
 * // returns {  carsharingDefaultDuration: false,  defaultCsMaximumBookingDuration: 3600, ... }
 * bookingRulesOperatorConfigs({
 *  csOperatorConfig: { allow_additional_driver: true, carsharing_default_duration: 15,  default_cs_maximum_booking_duration: undefined, ...,
 *  bookingType: 'carsharing'
 * })
 * @returns {object}
 */
export const bookingRulesOperatorConfigs = ({ csOperatorConfig, bookingType }) => {
  if (isEmpty(csOperatorConfig)) {
    const errorMessage = 'csOperatorConfig can not be empty';
    logger.message(errorMessage, LOG_TYPE.error);
    throw new Error(errorMessage);
  }
  if (!includes(values(BOOKING_TYPES), bookingType)) {
    const errorMessage = `Booking type unknown: ${bookingType}`;
    logger.message(errorMessage, LOG_TYPE.error);
    throw new Error(errorMessage);
  }

  const sanitizeConfigs = sanitizeCsOperatorConfigs(csOperatorConfig);
  const bookingRulesKeys = map(concat(get(CS_OPERATOR_KEYS_BY_BOOKING_TYPE, bookingType), CS_OPERATOR_COMMON_KEYS), camelCase);
  const bookingRulesBookingType = snakeCaseKeys(pick(sanitizeConfigs, bookingRulesKeys));

  return mapKeys(
    bookingRulesBookingType,
    (_configValue, configKey) => get(CS_OPERATOR_TIME_CONFIG_KEYS_REDUCER, configKey) || camelCase(configKey),
  );
};
