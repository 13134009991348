import result from 'lodash/result';

export const collectBrowserInfo = () => ({
  acceptHeader: 'application/json, text/plain, */*',
  screenWidth: result(window, 'screen.width.toString', ''),
  screenHeight: result(window, 'screen.height.toString', ''),
  colorDepth: result(window, 'screen.colorDepth.toString', ''),
  userAgent: result(window, 'navigator.userAgent', ''),
  timeZoneOffset: (new Date()).getTimezoneOffset().toString(),
  language: window.navigator.language || window.navigator.browserLanguage,
  javaEnabled: result(window, 'navigator.javaEnabled', false),
});
