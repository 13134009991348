import { logger } from '@emobg/web-utils';

const providerAddPaymentMethod = providerVersion => {
  logger.message(`addPaymentMethod: ${providerVersion}`);
  return import(`./${providerVersion}/ProviderAddPaymentMethod.vue`);
};

const providerUtils = providerVersion => {
  logger.message(`utils: ${providerVersion}`);

  return import(`./${providerVersion}/utils.js`);
};

const providerAPI = providerVersion => {
  logger.message(`API: ${providerVersion}`);

  return import(`./${providerVersion}/api/paymentMethodsApi.js`);
};

export { providerAddPaymentMethod, providerUtils, providerAPI };
