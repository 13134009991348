import omit from 'lodash/omit';
import some from 'lodash/some';
import { LOG_TYPE, logger } from '@emobg/web-utils';
import { storage } from './storage';
import {
  ACTION_USED,
  EDIT_BOOKING_QUERY,
  HUB_REDIRECT_FLOW,
  HUB_REDIRECT_FLOW_STATUS,
  PAYMENT_HUB_CURRENT_URL,
  PAYMENT_REDIRECTED,
  PROVIDER_USED,
  STORAGE_3DS_DATA,
} from '../constants/paymentMethodsHub';

const filteredKeys = [
  'matchingName',
  'bookingInvoiceUuid',
  'loopOrigin',
  'issuerComponent',
];

export const filterResponse = response => omit(response, filteredKeys);

// removes the observer from the response
export const parseResponse = response => JSON.parse(JSON.stringify(response));

export const removeUrlQuery = (currentPath, query) => {
  window.history.pushState({}, null, `${currentPath.replace(query, '')}`);
};

let validatorChecker = false;

const redirectLoopBroken = (to, PaymentStorage3dsData) => {
  let isValid = false;
  const validatorName = 'redirectLoopBroken';

  if (to.query.status === HUB_REDIRECT_FLOW_STATUS.toRedirect && PaymentStorage3dsData) {
    logger.message('>>> router flow: coming back from redirect (redirect loop broken)');

    removeUrlQuery(window.location.href, `?${HUB_REDIRECT_FLOW_STATUS.toRedirectQueryString}`);
    storage.remove(STORAGE_3DS_DATA);
    storage.remove(HUB_REDIRECT_FLOW);

    isValid = true;
    validatorChecker = true;
  }

  return {
    isValid,
    validatorName,
  };
};

const redirectLoopFinished = (to, from, PaymentStorage3dsData, PaymentHubRedirectFlow) => {
  let isValid = false;
  const validatorName = 'redirectLoopFinished';

  if (
    (to.query.status === HUB_REDIRECT_FLOW_STATUS.fromRedirect || to.query.status === HUB_REDIRECT_FLOW_STATUS.redirectStep0)
    && from?.name === null && PaymentStorage3dsData
    && PaymentHubRedirectFlow === HUB_REDIRECT_FLOW_STATUS.toRedirect
  ) {
    logger.message('>>> router flow: coming from redirect (redirect loop finished)');

    removeUrlQuery(window.location.href, HUB_REDIRECT_FLOW_STATUS.fromRedirectQueryString);
    storage.remove(HUB_REDIRECT_FLOW);
    storage.add(PAYMENT_REDIRECTED, true);

    isValid = true;
    validatorChecker = true;
  }

  return {
    isValid,
    validatorName,
  };
};

const backOnRedirectLoopFinished = (to, PaymentHubRedirectFlow) => {
  let isValid = false;
  const validatorName = 'backOnRedirectLoopFinished';

  if (to.query.status === HUB_REDIRECT_FLOW_STATUS.redirectStep0 && PaymentHubRedirectFlow === HUB_REDIRECT_FLOW_STATUS.redirectStep0) {
    logger.message('>>> router flow: redirect loop finished, went back to ext page and forward or refresh upon finished');

    storage.remove(HUB_REDIRECT_FLOW);

    isValid = true;
    validatorChecker = true;
  }

  return {
    isValid,
    validatorName,
  };
};

const refreshUponFinished = (to, PaymentHubRedirectFlow) => {
  let isValid = false;
  const validatorName = 'refreshUponFinished';

  if (to.name === 'bookings.myBookings.active' && !to.query.status && PaymentHubRedirectFlow === HUB_REDIRECT_FLOW_STATUS.redirectStep0) {
    logger.message('>>> router flow: redirect on edit booking loop finished, went back to ext page and forward or refresh upon finished');

    storage.add(HUB_REDIRECT_FLOW, HUB_REDIRECT_FLOW_STATUS.redirectReSent);

    isValid = true;
    validatorChecker = true;
  }

  return {
    isValid,
    validatorName,
  };
};

const resentFromRedirectPage = (to, PaymentHubRedirectFlow) => {
  let isValid = false;
  const validatorName = 'resentFromRedirectPage';

  if (to.name === 'bookings.myBookings.active' && to.query.status === HUB_REDIRECT_FLOW_STATUS.fromRedirect && PaymentHubRedirectFlow === HUB_REDIRECT_FLOW_STATUS.redirectStep0) {
    logger.message('>>> router flow: redirect on edit booking loop post re-sent from external redirect page');

    storage.add(HUB_REDIRECT_FLOW, HUB_REDIRECT_FLOW_STATUS.redirectReSent);

    isValid = true;
    validatorChecker = true;
  }

  return {
    isValid,
    validatorName,
  };
};

const resentExternalRedirectPage = (to, reSentCheckQueryStatus, reSentCheckRedirectFlow, PaymentHubRedirectFlow) => {
  let isValid = false;
  const validatorName = 'resentExternalRedirectPage';

  if (!validatorChecker
    && some(reSentCheckQueryStatus, status => to.query.status === status)
    && some(reSentCheckRedirectFlow, status => PaymentHubRedirectFlow === status)
  ) {
    logger.message('>>> router flow: coming from post re-sent from external redirect page');

    storage.add(HUB_REDIRECT_FLOW, HUB_REDIRECT_FLOW_STATUS.redirectReSent);

    isValid = true;
  }

  return {
    isValid,
    validatorName,
  };
};

export const checkRedirectFlowOnRouteEnter = (from, to) => {
  const PaymentHubCurrentUrl = storage.get(PAYMENT_HUB_CURRENT_URL);
  const PaymentStorage3dsData = storage.get(STORAGE_3DS_DATA);
  const PaymentHubRedirectFlow = storage.get(HUB_REDIRECT_FLOW);
  const reSentCheckQueryStatus = [
    HUB_REDIRECT_FLOW_STATUS.fromRedirect,
    HUB_REDIRECT_FLOW_STATUS.redirectStep0,
  ];

  const reSentCheckRedirectFlow = [
    HUB_REDIRECT_FLOW_STATUS.redirectStep0,
    HUB_REDIRECT_FLOW_STATUS.redirectReSent,
  ];

  const notRedirect = () => {
    const isValid = !PaymentStorage3dsData && !PaymentHubRedirectFlow && !PaymentHubCurrentUrl;

    validatorChecker = isValid;

    return {
      isValid,
      validatorName: 'notRedirect',
    };
  };

  const comingFromRefresh = () => {
    const isValid = PaymentHubCurrentUrl === window.location.pathname && !PaymentHubRedirectFlow;

    validatorChecker = isValid;

    return {
      isValid,
      validatorName: 'comingFromRefresh',
    };
  };

  const isNotRedirect = notRedirect();
  const isComingFromRefresh = comingFromRefresh();
  const isRedirectBroken = redirectLoopBroken(to, PaymentStorage3dsData);
  const isRedirectLoopFinished = redirectLoopFinished(to, from, PaymentStorage3dsData, PaymentHubRedirectFlow);
  const isBackOnRedirectLoopFinished = backOnRedirectLoopFinished(to, PaymentHubRedirectFlow);
  const isRefreshUponFinished = refreshUponFinished(to, PaymentHubRedirectFlow);
  const isResentFromRedirectPage = resentFromRedirectPage(to, PaymentHubRedirectFlow);
  const isResentExternalRedirectPage = resentExternalRedirectPage(
    to, reSentCheckQueryStatus, reSentCheckRedirectFlow, PaymentHubRedirectFlow,
  );

  const validators = [
    isNotRedirect,
    isComingFromRefresh,
    isRedirectBroken,
    isRedirectLoopFinished,
    isBackOnRedirectLoopFinished,
    isRefreshUponFinished,
    isResentFromRedirectPage,
    isResentExternalRedirectPage,
  ];

  let res = null;

  validators.forEach(validator => {
    if (validator.isValid) {
      res = validator;
    }
  });

  if (res === null) {
    logger.message('>>> router flow: situation not found', LOG_TYPE.error);
  }

  return res;
};

export const removeCommonStorageOnRouterLeave = () => {
  storage.remove(STORAGE_3DS_DATA);
  storage.remove(PROVIDER_USED);
  storage.remove(ACTION_USED);
  storage.remove(HUB_REDIRECT_FLOW);
  storage.remove(PAYMENT_HUB_CURRENT_URL);
  storage.remove(PAYMENT_REDIRECTED);
};

export const checkUrlQueryOnPageLeave = () => {
  // popstate event doesn't work on chrome until some user interaction is given, using onbeforeunload instead
  window.onbeforeunload = () => {
    const urlQuery = window.location.search;

    if (
      urlQuery === `?${HUB_REDIRECT_FLOW_STATUS.toRedirectQueryString}`
    ) {
      storage.add(HUB_REDIRECT_FLOW, HUB_REDIRECT_FLOW_STATUS.toRedirect);
    }

    if (
      urlQuery === `?${HUB_REDIRECT_FLOW_STATUS.redirectStep0QueryString}`
      || urlQuery.includes(EDIT_BOOKING_QUERY)
    ) {
      storage.add(HUB_REDIRECT_FLOW, HUB_REDIRECT_FLOW_STATUS.redirectStep0);
      storage.remove(STORAGE_3DS_DATA);
      storage.remove(PROVIDER_USED);
      storage.remove(ACTION_USED);
    }
  };
};
