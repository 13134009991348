import { LOG_TYPE, logger } from '@emobg/web-utils';

import { providerUtils } from '../../paymentMethodsProviders/PaymentProviderComponents';

import { storage } from '../storage';

import {
  ACTION_USED, ADYEN_VERSION_THRESHOLD, PAYMENT_TYPES, PROVIDER_USED, RESPONSE_STATUS, STAGE, STORAGE_3DS_DATA,
} from '../../constants/paymentMethodsHub';

export const toRedirect = async composablePayload => {
  const {
    originalResponse,
    parentActionCallback,
    contextData,
    setupProps,
  } = composablePayload;

  const { adyenVersion } = originalResponse;
  const AdyenVersionAboveThreshold = adyenVersion && (adyenVersion > ADYEN_VERSION_THRESHOLD);

  const utils = await providerUtils(setupProps.props.provider);

  const responseActionType = AdyenVersionAboveThreshold ? `${originalResponse.action.type}` : JSON.parse(originalResponse.action).type;

  const storedResponse = originalResponse;

  const actionConfiguration = {
    locale: setupProps.locale,
  };

  if (originalResponse.providerStatusRaw === RESPONSE_STATUS.redirectShopper && responseActionType.toUpperCase() === STAGE.redirect) {
    const paymentType = PAYMENT_TYPES.card;

    const {
      paymentMethodUuid, paymentData, reference, resourceType, resourceUuid, matchingName, matchingType, bookingInvoiceUuid, invoiceUuid,
    } = originalResponse;

    const savedData = {
      paymentData,
      paymentMethodUuid,
      reference,
      resourceType,
      resourceUuid,
      type: paymentType,
      matchingName,
      matchingType,
      bookingInvoiceUuid,
      invoiceUuid,
      adyenVersion,
    };

    storage.add(STORAGE_3DS_DATA, savedData);
    storage.add(PROVIDER_USED, setupProps.props.provider);
    storage.add(ACTION_USED, contextData.flowTracking.currentAction);

    parentActionCallback('toggleHelperComponent', true);

    await parentActionCallback('$nextTick', null, 'async');

    try {
      utils.createFromAction(actionConfiguration, storedResponse);
    } catch (error) {
      logger.message(`an error at createFromAction ocurred: ${error}`, LOG_TYPE.error);

      parentActionCallback('toggleHelperComponent', false);
    }
  } else {
    logger.message(`unhandled response: ${originalResponse}`, LOG_TYPE.warning);
  }
};
