<template lang="html">
  <section class="mainSection flex-column mh-100 d-flex">
    <slot name="header">
      <NavBar />
    </slot>
    <NotificationListManager />
    <div id="main-app-content">
      <slot name="main-content">
        Main content
      </slot>
    </div>
  </section>
</template>

<script>
import NavBar from '@Shared/components/Navbar/Navbar';

import NotificationListManager from '@/components/NotificationList/NotificationListManager';
import { useNotifications } from '@/composable/App/Notifications/useNotifications';
import isNil from 'lodash/isNil';
import { watch } from 'vue';
export default {
  components: {
    NavBar,
    NotificationListManager,
  },
  setup() {
    const { currentActiveNotification } = useNotifications();
    function setContentHeight(isNotificationVisible = false) {
      const defaultHeight = 0;
      const expandedHeight = 60;

      const contentElement = document.getElementById('main-app-content');
      if (!isNil(contentElement)) {
        contentElement.style.paddingTop = isNotificationVisible ? `${expandedHeight}px` : `${defaultHeight}px`;
      }
    }
    watch(
      () => currentActiveNotification.value,
      (notification) => {
        setContentHeight(!!notification.text);
      },
      { immediate: true },
    );
    return {
      setContentHeight,
    };
  },
};
</script>
