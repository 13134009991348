// handles other errors than provided by refusal reasons

// @see https://docs.adyen.com/development-resources/refusal-reasons
// https://docs.adyen.com/development-resources/testing/result-code-testing/testing-with-card-holder-name
// and https://docs.adyen.com/development-resources/testing/result-code-testing/adyen-response-codes

import isNull from 'lodash/isNull';
import { STAGE } from '../../constants/paymentMethodsHub';

import { matchCurrentAction } from './actionHelper';

export const onError = composablePayload => {
  const {
    originalResponse,
    parentActionCallback,
    contextData,
    commonEmit,
  } = composablePayload;

  const errorMsg = isNull(originalResponse.providerMessage) ? originalResponse.providerStatusRaw : originalResponse.providerMessage;

  const { currentAction } = contextData.flowTracking;

  const { isActionAdd, isActionPayment, isActionList } = matchCurrentAction(currentAction);

  if (isActionAdd) {
    parentActionCallback('onAddPaymentMethodError', errorMsg);
  }

  if (isActionList) {
    commonEmit('on:add-payment-method-error');
  }

  if (isActionPayment) {
    commonEmit('on:payment-error', errorMsg);
  }

  parentActionCallback('setStage', STAGE.refused);

  parentActionCallback('resetRedirect');
};
