import get from 'lodash/get';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';

import INPUT_TYPES from '@/constants/inputTypes';
import { compactArray } from '@emobg/web-utils';

export const composeAlgoliaCostAllocationValue = (algoliaCost, costAllocations) => {
  const costAllocationData = find(costAllocations, { uuid: algoliaCost.parent_uuid });
  if (isEmpty(costAllocationData)) {
    return '';
  }
  let composedCostAllocation = {
    name: get(costAllocationData, 'name'),
    value: algoliaCost.value,
  };
  if (algoliaCost.type === INPUT_TYPES.select) {
    const childrenCost = get(costAllocationData, 'children', []);
    const selectedCost = find(childrenCost, { uuid: algoliaCost.value });
    composedCostAllocation = {
      ...composedCostAllocation,
      value: get(selectedCost, 'code'),
    };
  }

  return composedCostAllocation;
};

export const parseAlgoliaCostAllocations = (algoliaCosts, endpointCosts) => {
  if (isEmpty(algoliaCosts)) {
    return [];
  }
  return compactArray(map(algoliaCosts, algoliaCost => composeAlgoliaCostAllocationValue(algoliaCost, endpointCosts)));
};

