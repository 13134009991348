import { LOG_TYPE, logger } from '@emobg/web-utils';

import { storage } from '../storage';

import {
  ACTIONS,
  ACTION_USED,
  HUB_REDIRECT_FLOW_STATUS,
  MATCHING_NAME,
  ORIGIN,
  PROVIDER_USED,
  SOURCE_PAGES,
  STORAGE_3DS_DATA,
} from '../../constants/paymentMethodsHub';

import { PaymentMethodsHubComposable } from '../PaymentMethodsHubComposable';

export const fromRedirect = async (utils, currentPath, storeData, callbackFn) => {
  const { redirectConfirmQuery, redirectQuery } = utils;

  const query = currentPath.match(redirectQuery) ? redirectQuery : redirectConfirmQuery;
  const replacedQuery = currentPath.replace(query, HUB_REDIRECT_FLOW_STATUS.fromRedirectQueryString);
  const removeQuery = window.location.href.replace(HUB_REDIRECT_FLOW_STATUS.fromRedirectQueryString, '');

  const { getProfileInfo } = PaymentMethodsHubComposable(storeData);

  window.history.replaceState({}, null, replacedQuery);

  window.history.pushState({}, null, removeQuery);

  const actionUsed = storage.get(ACTION_USED);
  const isActionAdd = actionUsed === ACTIONS.add;

  const savedData = {
    ...storage.get(STORAGE_3DS_DATA),
    actionUsed,
    isActionAdd,
  };

  storage.remove(STORAGE_3DS_DATA);

  let origin = ORIGIN.redirect;

  let providerMessage = null;

  if (!savedData) {
    logger.message('no data in storage', LOG_TYPE.error);
    return null;
  }

  const customerData = getProfileInfo();

  let confirmRedirectResponse = null;
  let commonResourceResponse = null;

  const isResource = Boolean(savedData.resourceType && savedData.resourceUuid && savedData.reference);

  const isBooking = Boolean(savedData.matchingType === SOURCE_PAGES.booking && savedData.bookingInvoiceUuid);

  const isInvoice = Boolean(savedData.invoiceUuid && savedData.reference);

  const commonCatch = error => {
    logger.message(`an error at fromRedirect ocurred: ${error}`, LOG_TYPE.error);

    origin = ORIGIN.forcedError;

    providerMessage = error;
  };

  if (isInvoice) {
    storage.add(MATCHING_NAME, savedData.matchingName);
  }

  /**
   * we have to maintain 2 returns since isInvoice and isBooking or isResource can overlap
   */
  if (isInvoice || isActionAdd) {
    try {
      confirmRedirectResponse = await utils.storeRedirectPaymentMethod(savedData, customerData);
    } catch (error) {
      commonCatch(error);
    }

    storage.remove(PROVIDER_USED);
    storage.remove(ACTION_USED);

    return callbackFn({ ...confirmRedirectResponse, actionUsed }, origin, providerMessage);
  }

  const commonRedirectResponse = async resourceResponse => {
    const redirectResponse = {
      reference: savedData.reference,
      payment_data: savedData.paymentData,
      session: resourceResponse.MD || resourceResponse.md,
      pa_response: resourceResponse.PaRes || resourceResponse.paRes,
      redirect_result: resourceResponse.redirectResult,
    };

    storage.add(MATCHING_NAME, savedData.matchingName);

    confirmRedirectResponse = await utils.postConfirmRedirect(redirectResponse);
  };

  if (isResource) {
    try {
      commonResourceResponse = await utils.getRedirectResult(savedData.reference);

      commonRedirectResponse(commonResourceResponse);
    } catch (error) {
      commonCatch(error);
    }
  }

  if (isBooking) {
    try {
      commonResourceResponse = await utils.getBookingPaymentResult(savedData.bookingInvoiceUuid);

      commonRedirectResponse(commonResourceResponse);
    } catch (error) {
      commonCatch(error);
    }
  }

  storage.remove(PROVIDER_USED);
  storage.remove(ACTION_USED);

  return callbackFn({ ...confirmRedirectResponse, actionUsed }, origin, providerMessage);
};
