import map from 'lodash/map';
import isString from 'lodash/isString';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import compact from 'lodash/compact';
import size from 'lodash/size';
import pad from 'lodash/pad';
import trim from 'lodash/trim';
import isNil from 'lodash/isNil';
import get from 'lodash/get';

import { LOG_TYPE, logger } from '@emobg/web-utils';
import { EMPLOYEE_STATUS } from '@/constants/employeeStatus';

import { wrapString } from '@/utils/normalize';
import { ALGOLIA_FILTERS_OPERATORS } from '@/constants/algoliaFilters';

export const getAlgoliaFilterExcludingValues = (field, data = []) => {
  const isFieldParamValid = isString(field) && !isEmpty(field);
  const isDataParamValid = isArray(data) && data.every(item => isString(item));

  if (!(isFieldParamValid && isDataParamValid)) {
    return null;
  }

  const filter = map(data, item => `${field}:'${item}'`).join(` ${ALGOLIA_FILTERS_OPERATORS.and} ${ALGOLIA_FILTERS_OPERATORS.not} `);

  return filter ? `${ALGOLIA_FILTERS_OPERATORS.not} ${filter}` : null;
};

export const algoliaFilterForAdditionalDrivers = (
  additionalDrivers,
  companyUuid,
  ownerEmail,
) => {
  const actualAdditionalDrivers = getAlgoliaFilterExcludingValues(
    'email',
    additionalDrivers,
  );

  if (isEmpty(companyUuid)) {
    throw new Error('companyUuid can not be falsy');
  }

  const companyFilter = `company_uuid:'${companyUuid}'`;
  const statusFilter = `dedicated_fleet_status:'${EMPLOYEE_STATUS.activated}'`;
  const ownerEmailFilter = `${
    ownerEmail ? ` ${ALGOLIA_FILTERS_OPERATORS.and} ${ALGOLIA_FILTERS_OPERATORS.not} email:'${ownerEmail}'` : ''
  }`;
  const additionalDriversFilter = actualAdditionalDrivers ? ` ${ALGOLIA_FILTERS_OPERATORS.and} ${actualAdditionalDrivers}` : '';

  return `${companyFilter} ${ALGOLIA_FILTERS_OPERATORS.and} ${statusFilter}${ownerEmailFilter}${additionalDriversFilter}`;
};

/**
 * It compose a string using the field and the value passed by argument.
 * It takes care of wrapping the value and concat the values if those are an array.
 * @param {string} indexField
 * @param {Array} indexValues
 * @param {string} concatOperator
 * @example <caption>Example usage of generateFilterBy.</caption>
 * // returns "user_name:'Duncan' AND user_name:'Garney'"
 * generateFilterBy('user_name', ['Duncan', 'Garney'], 'AND')
 * @return {string}
 */
export const generateFilterBy = (
  indexField,
  indexValues = [],
  concatOperator = ALGOLIA_FILTERS_OPERATORS.and,
) => {
  const sanitizedValues = compact(indexValues);

  if (isEmpty(indexField)) {
    const message = 'Missing mandatory argument: indexField';
    logger.message(message, LOG_TYPE.error);
    throw new Error(message);
  }

  if (isEmpty(concatOperator)) {
    const message = 'Argument concatOperator bad format: Argument must be a non empty string';
    logger.message(message, LOG_TYPE.error);
    throw new Error(message);
  }

  if (isEmpty(sanitizedValues)) {
    return '';
  }

  return trim(
    map(
      sanitizedValues,
      indexValue => `${indexField}:${isString(indexValue) ? wrapString(indexValue, "'") : indexValue}`,
    ).join(pad(concatOperator, size(concatOperator) + 2)),
  );
};

/**
 * It joins to strings (expected filter format) with the operator passed by argument
 * It also wraps those in parenthesis to make them a filter group
 * @param {Array} filters
 * @param {string} concatOperator
 * @example <caption>Example usage of groupFiltersBy.</caption>
 * // returns "(user_name:'Duncan' OR clan:'Garney')"
 * groupFiltersBy(["user_name:'Duncan'", "clan:'Atreides'"], 'OR')
 * @returns {string}
 */
export const groupFiltersBy = (
  filters,
  concatOperator = ALGOLIA_FILTERS_OPERATORS.and,
) => {
  const sanitizedFilters = compact(filters);

  if (isNil(filters)) {
    const message = 'Missing mandatory argument: filters';
    logger.message(message, LOG_TYPE.error);
    throw new Error(message);
  }

  if (isEmpty(concatOperator)) {
    const message = 'Argument concatOperator bad format: Argument must be a non empty string';
    logger.message(message, LOG_TYPE.error);
    throw new Error(message);
  }

  if (isEmpty(sanitizedFilters)) {
    return '';
  }

  return wrapString(
    trim(
      sanitizedFilters.join(pad(concatOperator, size(concatOperator) + 2)),
    ),
    ['(', ')'],
  );
};

/**
 * Get circles uuids from a permission that user can manage
 * @param {object} aclService
 * @param {string|string[]} permissions
 * @return {string[]}
 */
export const createFilterByPermissions = (aclService, permissions) => {
  const hasUserPermission = aclService.hasUserPermissions(permissions);
  const isNotManager = !get(aclService, 'rawCirclesPermissions.length');

  return hasUserPermission || isNotManager
    ? []
    : aclService.getCirclesByPermission(permissions);
};

export const concatFilterByPermission = parameters => {
  const {
    currentFilter = '',
    circlesUuids = [],
    field = 'uuid',
    extraCircleCondition = '',
  } = parameters;

  let finalFilter = currentFilter;

  if (circlesUuids) {
    const uuids = isString(circlesUuids) ? [circlesUuids] : circlesUuids;
    const arrFilterByCirclesUuids = map(uuids, uuid => `${field}:'${uuid}'`);
    const filterByCirclesUuids = compact(arrFilterByCirclesUuids).join(' OR ');
    if (filterByCirclesUuids) {
      finalFilter = `${currentFilter} AND (${filterByCirclesUuids}${extraCircleCondition})`;
    }
  }

  return finalFilter;
};
