export const MOMENT_METHODS = {
  days: 'days',
  week: 'week',
  weekday: 'weekday',
  month: 'month',
};

export const DATE_LOCALES = {
  en: 'en',
};

export const DATE_UNITS = {
  hour: 'hour',
  hours: 'hours',
  day: 'day',
  days: 'days',
  minutes: 'minutes',
  month: 'month',
  months: 'months',
  weeks: 'weeks',
  years: 'years',
};
