export const SEGMENT_EVENTS = {
  returnLocationUpdated: 'Return Location updated',
  carsharingBookingCreated: 'Carsharing Booking Created',
  pickStackLocationAvailabilityType: 'Pick Stack Location Availability Type',
  skipSuggestedTime: 'Skip Suggested Time',
  selectSuggestedTime: 'Select Suggested Time',
  lookForSuggestions: 'Look For Suggestions',
  editBookingTime: 'Edit Booking Time',
  editBookingAdditionalDriver: 'Edit Booking Additional Driver',
  editBookingPassengers: 'Edit Booking Passengers',
  upgradeInsurance: 'Edit Booking - Upgrade Insurance',
  cancelBooking: 'Edit Booking - Cancel Booking',
  tfaVerificationCode: '2FA verification code',
  tfaCodeSet: '2FA code set',
  tfaResendCode: '2FA resend code',
  tfaFailed: '2FA Failed',
};
