import { storage } from '../storage';

import {
  ACTIONS, ACTION_USED, ORIGIN, STAGE,
} from '../../constants/paymentMethodsHub';

import { matchCurrentAction } from './actionHelper';

export const onAuthorised = composablePayload => {
  const {
    originalResponse,
    parentActionCallback,
    contextData,
    commonEmit,
  } = composablePayload;

  const actionUsed = storage.get(ACTION_USED);

  const isAddInRedirected = contextData.isRedirected && actionUsed === ACTIONS.add;

  const { currentAction } = contextData.flowTracking;

  const { isActionAdd, isActionList, isActionPayment } = matchCurrentAction(currentAction);

  if (isActionAdd || isAddInRedirected || isActionList) {
    commonEmit('on:add-payment-method-authorised', originalResponse);

    parentActionCallback('resetRedirect');
  }

  if (isActionAdd) {
    parentActionCallback('setStage', STAGE.submitted);

    if (!contextData.flowTracking.addIsEmbedded) {
      parentActionCallback('onAddPaymentMethodClose');
    }

    const loopOriginAllowed = [ORIGIN.challenge, ORIGIN.identify];

    if (loopOriginAllowed.includes(originalResponse.loopOrigin)) {
      parentActionCallback('linkDefaultPaymentMethodOnAdd', originalResponse);
    }
  }

  if (isActionAdd || isActionList) {
    parentActionCallback('refreshPaymentMethodsCollection');
  }

  parentActionCallback('toggleHelperComponentLoader', false);

  parentActionCallback('resetFlow');

  if (isActionPayment) {
    parentActionCallback('trackInternalPayment', originalResponse);
    parentActionCallback('saveResponse', originalResponse);
  }
};
