var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.selectInBackground)?_c('div',{class:[
      'SelectPaymentMethod',
      {'hide-cvc': _vm.hideCvc},
      {'empty emobg-border-radius-medium emobg-background-color-ground-lighter px-2 py-3': _vm.showAddPaymentMethodOnSelect && _vm.isAddPaymentAvailable}
    ]},[(_vm.showAddPaymentMethodOnSelect && _vm.isAddPaymentAvailable)?_c('div',{staticClass:"add-payment-method--wrapper"},[_c('ui-button',_vm._b({staticClass:"ml-n3 pl-2 add-button",attrs:{"disabled":_vm.isAddPaymentMethodModalOpen && _vm.isUnfoldAddEmbedded,"face":_vm.FACES.text,"data-test-id":"new_payment_method-button"},on:{"click":_vm.onClickAddPaymentMethod}},'ui-button',_vm.fetchButtonSpecs({ buttonType: _vm.THEME_BUTTON_TYPES.TERTIARY }),false),[_vm._v(" "+_vm._s(_vm.$t('common.payment_method.add_new_method'))+" ")])],1):_vm._e(),(!_vm.isEmptyPaymentMethodsCollection(_vm.profile))?_c('div',{staticClass:"row label-select-outside"},[_c('span',{staticClass:"col-12 emobg-font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('settings.payment_method.title'))+" ")])]):_vm._e(),(!_vm.isEmptyPaymentMethodsCollection(_vm.profile))?_c('ui-select',{staticClass:"w-100 payment-method-select",attrs:{"value":_vm.currentPaymentMethodUUID,"disabled":_vm.selectLocked,"name":"paymentMethodsSelect","data-test-id":"payment_method-select"}},[_c('div',{staticClass:"ml-n2 mr-0 d-flex justify-content-end align-items-center",attrs:{"slot":"selected-component"},slot:"selected-component"},[_c('ui-icon',{staticClass:"p-2",attrs:{"icon":_vm.selectedPaymentMethod.icon}}),_c('span',[_vm._v(_vm._s(_vm.selectedPaymentMethod.label))])],1),_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('ul',_vm._l((_vm.normalizedPaymentMethods),function({ label, value, icon }){return _c('li',{key:value,staticClass:"d-flex align-items-center emobg-background-color-ground-lighter-hover cursor-pointer",class:{'emobg-background-color-ground-light': _vm.currentPaymentMethodUUID === value},on:{"click":() => {
              _vm.currentPaymentMethodUUID = value;
              _vm.selectedPaymentMethod = { label, value, icon };
              _vm.onSelectPaymentMethod();
            }}},[_c('ui-icon',{staticClass:"p-2",attrs:{"icon":icon}}),_c('span',[_vm._v(_vm._s(label))])],1)}),0),(_vm.isAddPaymentAvailable)?_c('div',{staticClass:"add-payment-wrapper"},[_c('ui-button',_vm._b({staticClass:"add-button",attrs:{"data-test-id":"add_payment_method-button"},on:{"click":_vm.onClickAddPaymentMethod}},'ui-button',_vm.fetchButtonSpecs({ buttonType: _vm.THEME_BUTTON_TYPES.TERTIARY }),false),[_vm._v(" "+_vm._s(_vm.$t('common.payment_method.add_method'))+" ")])],1):_vm._e()])]):_vm._e(),(!_vm.hideCvc && _vm.isCard)?_c('div',[_c('ui-loader',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isCVCReady),expression:"!isCVCReady"}],staticClass:"mt-4 cvc-loader",attrs:{"data-test-id":"cvc-loader"}}),_c(_vm.CVCComponentFromProvider,{directives:[{name:"show",rawName:"v-show",value:(_vm.isCVCReady),expression:"isCVCReady"}],key:_vm.currentPaymentMethod.uuid,tag:"Component",attrs:{"locale":_vm.locale,"user-uuid":_vm.userUuid,"provider":_vm.provider,"cvc-config":_vm.CVCInfo,"only-cvc":"","data-test-id":"cvc_provider-component"},on:{"on:cvc-ready":_vm.onCVCReady,"update:cvc-candidate-payment-method":_vm.onCVCCandidatePaymentMethod}}),(_vm.showHelperComponent)?_c(_vm.helperComponent,{tag:"Component"}):_vm._e(),(_vm.showHelperComponentLoader)?_c('ui-loader'):_vm._e()],1):_vm._e()],1):_vm._e(),(_vm.selectInBackground)?[(_vm.showAddPaymentMethodOnSelect)?_c('ui-badge',{staticClass:"p-3"},[_vm._v(" "+_vm._s(_vm.$t('payments.payment_methods.no_payment'))+" ")]):_vm._e(),(_vm.showAddPaymentMethodOnSelect)?_c('div',{staticClass:"emobg-border-radius-medium emobg-background-color-ground-lighter p-3"},[_c('span',[_vm._v(_vm._s(_vm.$t('payments.payment_methods.no_payment')))])]):_vm._e(),(_vm.showHelperComponent)?_c(_vm.helperComponent,{tag:"Component"}):_vm._e(),(_vm.showHelperComponentLoader)?_c('ui-loader'):_vm._e()]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }