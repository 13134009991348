<template>
  <div class="AddPaymentMethod">
    <div v-if="isUnfoldAddEmbedded">
      <div>
        <AlertComponent
          v-if="errorMessage"
          :type="alertTypes.danger"
          class="w-100 my-2 d-flex"
          data-test-id="add_payment-alert"
        >
          {{ errorMessage }}
        </AlertComponent>
      </div>

      <Component
        :is="AddPaymentMethodFromProvider"
        v-show="isReady"
        :locale="locale"
        :user-uuid="userUuid"
        :class="{disabled: isDisabled}"
        :provider="provider"
        data-test-id="add_payment_method_provider-component"
        @update:provider-candidate-payment-method="onProviderCandidatePaymentMethod"
        @on:error="onError"
        @on:ready="onReady"
      />
      <ui-loader
        v-if="!isReady"
        class="embedded-add-loader"
      />

      <div
        class="d-flex align-items-center
            emobg-border-color-ground-light py-3
            emobg-background-color-white emobg-border-radius-medium"
      >
        <ui-button
          v-bind="fetchButtonSpecs({ buttonType: THEME_BUTTON_TYPES.SECONDARY })"
          :disabled="isDisabled && cancelButtonDisabled"
          class="mr-2 px-4 my-2"
          data-test-id="cancel_add_payment_method-button"
          @clickbutton="onCancel"
        >
          {{ $t('buttons.cancel') }}
        </ui-button>

        <ui-button
          v-bind="fetchButtonSpecs()"
          :disabled="!isValid || isDisabled"
          :loading="isLoading"
          :type="INPUT_TYPES.submit"
          class="mr-2 px-4 my-2"
          data-test-id="submit_add_payment-button"
          @clickbutton="onSubmit"
        >
          {{ $t('common.payment_method.add_method') }}
        </ui-button>
      </div>

      <Component
        :is="helperComponent"
        v-if="showHelperComponent"
      />
    </div>

    <MuiModal
      v-else
      :is-open="isOpenModal"
    >
      <template #body>
        <div class="p-5 add-payment-method-implementation">
          <h2
            class="emobg-font-large mb-3"
            v-text="$t('modal.payment_method.title')"
          />

          <AlertComponent
            v-if="errorMessage"
            :type="alertTypes.danger"
            class="w-100 mb-2 d-flex"
            data-test-id="add_payment-alert"
          >
            {{ errorMessage }}
          </AlertComponent>

          <Component
            :is="AddPaymentMethodFromProvider"
            v-show="isReady"
            :locale="locale"
            :user-uuid="userUuid"
            :class="{disabled: isDisabled}"
            :provider="provider"
            data-test-id="add_payment_method_provider-component"
            @update:provider-candidate-payment-method="onProviderCandidatePaymentMethod"
            @on:error="onError"
            @on:ready="onReady"
          />
          <ui-loader v-if="!isReady" />
        </div>
      </template>

      <template #footer>
        <slot name="footer">
          <Component
            :is="helperComponent"
            v-if="showHelperComponent"
          />
          <div
            class="d-flex align-items-center justify-content-end
            emobg-border-top-1 emobg-border-color-ground-light px-5 pt-3 pb-4
            emobg-background-color-white emobg-border-radius-medium"
          >
            <ui-button
              v-bind="fetchButtonSpecs({ buttonType: THEME_BUTTON_TYPES.SECONDARY })"
              :disabled="isDisabled && cancelButtonDisabled"
              :face="FACES.outline"
              class="emobg-color-white-contrast mr-3"
              data-test-id="cancel_add_payment_method-button"
              @clickbutton="onCancel"
            >
              {{ $t('buttons.cancel') }}
            </ui-button>

            <ui-button
              v-bind="fetchButtonSpecs()"
              :disabled="!isValid || isDisabled"
              :type="INPUT_TYPES.submit"
              :loading="isLoading"
              data-test-id="submit_add_payment-button"
              @clickbutton="onSubmit"
            >
              {{ $t('common.payment_method.add') }}
            </ui-button>
          </div>
        </slot>
      </template>
    </MuiModal>
  </div>
</template>

<script>
import includes from 'lodash/includes';
import { MuiModal } from '@emobg/motion-ui/v1';

import AlertComponent from '@/components/Alert/AlertComponent';
import ALERT_TYPES from '@/components/Alert/alertTypes';
import { useTheme } from '@/composable/Theme/useTheme';

import { providerAddPaymentMethod, providerUtils } from './paymentMethodsProviders/PaymentProviderComponents';

import { STAGE } from './constants/paymentMethodsHub';

export default {
  name: 'AddPaymentMethod',

  components: {
    AlertComponent,
    MuiModal,
  },

  props: {
    locale: {
      type: String,
      required: true,
    },

    userUuid: {
      type: String,
      required: true,
    },

    isUnfoldAddEmbedded: {
      type: Boolean,
      default: false,
    },

    isOpenModal: {
      type: Boolean,
      default: false,
    },

    stage: {
      default: STAGE.editable,
      validator: value => includes(STAGE, value),
    },

    provider: {
      type: String,
      required: true,
    },

    errorMessage: {
      type: String,
      default: '',
    },

    showHelperComponent: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const { fetchButtonSpecs } = useTheme();
    return { fetchButtonSpecs };
  },

  data() {
    return {
      isReady: false,
      isValid: false,
      isLoading: false,
      isDisabled: false,

      cancelButtonDisabled: true,

      helperComponent: null,
    };
  },

  computed: {
    AddPaymentMethodFromProvider() {
      return () => providerAddPaymentMethod(this.provider);
    },
  },

  watch: {
    stage(newStage) {
      switch (newStage) {
        case STAGE.submitting:
          this.isLoading = true;
          this.isDisabled = true;
          break;

        case STAGE.submitted:
          this.isLoading = false;
          this.isDisabled = true;
          break;

        case STAGE.editable:
        case STAGE.refused:
          this.isLoading = false;
          this.isDisabled = false;
          break;

        case STAGE.threeDS2Challenge:
          this.isLoading = false;
          this.isDisabled = true;
          this.cancelButtonDisabled = false;
          break;

        default:
          break;
      }
    },

    async showHelperComponent() {
      await this.getProviderHelperComponent();
    },
  },

  created() {
    this.alertTypes = ALERT_TYPES;

    this.getProviderHelperComponent();
  },

  methods: {
    onProviderCandidatePaymentMethod(candidatePaymentMethod) {
      this.isValid = candidatePaymentMethod.isValid;

      this.$emit('update:candidate-payment-method', candidatePaymentMethod);
    },

    onError(addPaymentMethodError) {
      this.$emit('on:error', addPaymentMethodError);
    },

    onReady() {
      this.isReady = true;
    },

    onSubmit() {
      this.$emit('on:submit');
    },

    onCancel() {
      this.$emit('on:close');
    },

    async getProviderHelperComponent() {
      const createdUtils = await providerUtils(this.provider);

      if (createdUtils) {
        const { providerHelperComponent } = createdUtils;

        this.helperComponent = providerHelperComponent;
      } else {
        this.helperComponent = null;
      }
    },
  },
};
</script>

<style lang="scss">
  .AddPaymentMethod {
    .add-payment-method-implementation {
      min-height: 280px;
    }

    ui-loader {
      display: block;
      margin-top: 20%;

      &.embedded-add-loader {
        margin-top: 0;
      }
    }
  }
</style>
