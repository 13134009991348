import isString from 'lodash/isString';
import omit from 'lodash/omit';

import { LOG_TYPE, camelCaseKeys, logger } from '@emobg/web-utils';

import { onAuthorised } from './responses/onAuthorised';
import { onChallenge } from './responses/onChallenge';
import { onIdentify } from './responses/onIdentify';
import { toRedirect } from './responses/toRedirect';
import { fromRedirect } from './responses/fromRedirect';
import { onRefused } from './responses/onRefused';
import { onError } from './responses/onError';

import { ORIGIN, RESPONSE_STATUS } from '../constants/paymentMethodsHub';

export const onResponseParent = storeData => {
  let onComposableResponse;

  const checkLoop = (response, origin, providerMessage) => {
    if (
      (origin === ORIGIN.challenge && response?.providerStatusRaw === RESPONSE_STATUS.challengeShopper)
      || (origin === ORIGIN.identify && response?.providerStatusRaw === RESPONSE_STATUS.identifyShopper)
    ) {
      logger.message(`in a redirect loop for response ${response.providerStatusRaw} - breaking...`, LOG_TYPE.error);

      return;
    }

    const filteredKeys = [
      'parentActionCallback',
      'contextData',
      'commonEmit',
      'setupProps',
    ];

    let filteredResponse = omit(response, filteredKeys);
    filteredResponse = {
      ...filteredResponse,
      loopOrigin: origin,
    };

    let loopResponse = {
      originalResponse: filteredResponse,
      parentActionCallback: response.parentActionCallback,
      contextData: response.contextData,
      commonEmit: response.commonEmit,
      setupProps: response.setupProps,
    };

    if (!response?.providerStatus && (origin === ORIGIN.redirect && response.number && response.expiry)) {
      loopResponse = {
        ...loopResponse,
        providerStatus: RESPONSE_STATUS.redirectAuthorised,
      };
    }

    if (origin === ORIGIN.forcedError) {
      loopResponse = {
        ...loopResponse,
        providerStatus: RESPONSE_STATUS.refused,
        providerMessage,
      };
    }

    onComposableResponse(loopResponse);
  };

  const onFromRedirect = async (utils, currentPath, data = storeData, callback = checkLoop) => {
    await fromRedirect(utils, currentPath, data, callback);
  };

  onComposableResponse = composablePayload => {
    // @see https://docs.adyen.com/online-payments/payment-result-codes for a complete list of possible result codes

    const { originalResponse } = composablePayload;

    const response = camelCaseKeys(originalResponse);

    switch (response.providerStatus) {
      case RESPONSE_STATUS.authorised:
      case RESPONSE_STATUS.redirectAuthorised:
        onAuthorised(composablePayload);
        break;

      case RESPONSE_STATUS.refused:
        onRefused(composablePayload);
        break;

      case RESPONSE_STATUS.pending:
        toRedirect(composablePayload);
        break;

      case RESPONSE_STATUS.challenge:
        onChallenge(composablePayload, storeData, checkLoop);
        break;

      case RESPONSE_STATUS.identify:
        onIdentify(composablePayload, storeData, checkLoop);
        break;

      case RESPONSE_STATUS.cancelled:
      case RESPONSE_STATUS.error:
      case RESPONSE_STATUS.presentToShopper:
        onError(composablePayload);
        break;

      case RESPONSE_STATUS.received:
        onAuthorised(composablePayload);
        break;

      default:
        if (isString(response.providerStatus)) {
          logger.message(`unhandled response received: ${response.providerStatus}`, LOG_TYPE.warning);
        } else {
          logger.message('unhandled response received (Object):');
          logger.message(response, LOG_TYPE.warning);
        }

        break;
    }
  };

  return {
    onComposableResponse,
    onFromRedirect,
  };
};
