<template>
  <div>
    <div
      v-if="!selectInBackground"
      :class="[
        'SelectPaymentMethod',
        {'hide-cvc': hideCvc},
        {'empty emobg-border-radius-medium emobg-background-color-ground-lighter px-2 py-3': showAddPaymentMethodOnSelect && isAddPaymentAvailable}
      ]"
    >
      <div
        v-if="showAddPaymentMethodOnSelect && isAddPaymentAvailable"
        class="add-payment-method--wrapper"
      >
        <ui-button
          v-bind="fetchButtonSpecs({ buttonType: THEME_BUTTON_TYPES.TERTIARY })"
          :disabled="isAddPaymentMethodModalOpen && isUnfoldAddEmbedded"
          :face="FACES.text"
          class="ml-n3 pl-2 add-button"
          data-test-id="new_payment_method-button"
          @click="onClickAddPaymentMethod"
        >
          {{ $t('common.payment_method.add_new_method') }}
        </ui-button>
      </div>

      <div
        v-if="!isEmptyPaymentMethodsCollection(profile)"
        class="row label-select-outside"
      >
        <span class="col-12 emobg-font-weight-bold">
          {{ $t('settings.payment_method.title') }}
        </span>
      </div>
      <ui-select
        v-if="!isEmptyPaymentMethodsCollection(profile)"
        :value="currentPaymentMethodUUID"
        :disabled="selectLocked"
        name="paymentMethodsSelect"
        class="w-100 payment-method-select"
        data-test-id="payment_method-select"
      >
        <div
          slot="selected-component"
          class="ml-n2 mr-0 d-flex justify-content-end align-items-center"
        >
          <ui-icon
            :icon="selectedPaymentMethod.icon"
            class="p-2"
          />
          <span>{{ selectedPaymentMethod.label }}</span>
        </div>

        <div slot="content">
          <ul>
            <li
              v-for="({ label, value, icon }) in normalizedPaymentMethods"
              :key="value"
              class="d-flex align-items-center emobg-background-color-ground-lighter-hover cursor-pointer"
              :class="{'emobg-background-color-ground-light': currentPaymentMethodUUID === value}"
              @click="() => {
                currentPaymentMethodUUID = value;
                selectedPaymentMethod = { label, value, icon };
                onSelectPaymentMethod();
              }"
            >
              <ui-icon
                :icon="icon"
                class="p-2"
              />
              <span>{{ label }}</span>
            </li>
          </ul>
          <div
            v-if="isAddPaymentAvailable"
            class="add-payment-wrapper"
          >
            <ui-button
              v-bind="fetchButtonSpecs({ buttonType: THEME_BUTTON_TYPES.TERTIARY })"
              class="add-button"
              data-test-id="add_payment_method-button"
              @click="onClickAddPaymentMethod"
            >
              {{ $t('common.payment_method.add_method') }}
            </ui-button>
          </div>
        </div>
      </ui-select>

      <div v-if="!hideCvc && isCard">
        <ui-loader
          v-show="!isCVCReady"
          class="mt-4 cvc-loader"
          data-test-id="cvc-loader"
        />
        <Component
          :is="CVCComponentFromProvider"
          v-show="isCVCReady"
          :key="currentPaymentMethod.uuid"
          :locale="locale"
          :user-uuid="userUuid"
          :provider="provider"
          :cvc-config="CVCInfo"
          only-cvc
          data-test-id="cvc_provider-component"
          @on:cvc-ready="onCVCReady"
          @update:cvc-candidate-payment-method="onCVCCandidatePaymentMethod"
        />

        <Component
          :is="helperComponent"
          v-if="showHelperComponent"
        />
        <ui-loader v-if="showHelperComponentLoader" />
      </div>
    </div>
    <template v-if="selectInBackground">
      <ui-badge
        v-if="showAddPaymentMethodOnSelect"
        class="p-3"
      >
        {{ $t('payments.payment_methods.no_payment') }}
      </ui-badge>
      <div
        v-if="showAddPaymentMethodOnSelect"
        class="emobg-border-radius-medium emobg-background-color-ground-lighter p-3"
      >
        <span>{{ $t('payments.payment_methods.no_payment') }}</span>
      </div>
      <Component
        :is="helperComponent"
        v-if="showHelperComponent"
      />
      <ui-loader v-if="showHelperComponentLoader" />
    </template>
  </div>
</template>

<script>
import map from 'lodash/map';
import find from 'lodash/find';
import first from 'lodash/first';

import { mapGetters, mapState } from 'vuex';

import { ICONS } from '@emobg/web-components';

import {
  isNilValue, LOG_TYPE, logger, maskCardNumber,
} from '@emobg/web-utils';
import { useTheme } from '@/composable/Theme/useTheme';

import { isTypeCard, maskIbanNumber, paymentMethodIcon } from './PaymentMethodsUtils';

import { nameSpace as PaymentMethodsHubNameSpace } from './stores/PaymentMethodsHub';

import { PAYMENT_TYPES } from './constants/paymentMethodsHub';

import { providerAddPaymentMethod, providerUtils } from './paymentMethodsProviders/PaymentProviderComponents';

export default {
  name: 'SelectPaymentMethod',

  props: {
    userUuid: {
      type: String,
      required: true,
    },

    profile: {
      type: Object,
      required: true,
    },

    provider: {
      type: String,
      required: true,
    },

    locale: {
      type: String,
      required: true,
    },

    hideCvc: {
      type: Boolean,
      default: false,
    },

    selectLocked: {
      type: Boolean,
      default: false,
    },

    showHelperComponent: {
      type: Boolean,
      default: false,
    },

    showHelperComponentLoader: {
      type: Boolean,
      default: false,
    },

    isAddPaymentMethodModalOpen: {
      type: Boolean,
      default: false,
    },

    isAddPaymentAvailable: {
      type: Boolean,
      default: true,
    },

    isUnfoldAddEmbedded: {
      type: Boolean,
      default: false,
    },

    selectInBackground: {
      type: Boolean,
      default: false,
    },

    isPaymentMethodsCollectionLoading: {
      type: Boolean,
      default: false,
    },

    forceUnmountCvc: {
      type: Boolean,
      default: false,
    },

  },

  setup() {
    const { fetchButtonSpecs } = useTheme();

    return { fetchButtonSpecs };
  },

  data() {
    return {
      isCVCReady: false,
      isCVCValid: false,

      currentPaymentMethodUUID: null,
      currentPaymentMethod: null,

      selectedPaymentMethod: {},

      showAddPaymentMethodOnSelect: false,

      overrideCVCComponentFromProvider: false,
    };
  },

  computed: {
    ...mapState(PaymentMethodsHubNameSpace, [
      'paymentMethodsCollectionB2B',
      'paymentMethodsCollectionB2C',
    ]),

    ...mapGetters(PaymentMethodsHubNameSpace, [
      'getDefaultPaymentMethodUUID',
      'getPaymentMethodByUUID',
      'isEmptyPaymentMethodsCollection',
    ]),

    paymentMethodsCollection() {
      return this.profile.isBusinessInCompanyPays
        ? this.paymentMethodsCollectionB2B
        : this.paymentMethodsCollectionB2C;
    },

    CVCComponentFromProvider() {
      if (this.overrideCVCComponentFromProvider) {
        return null;
      }

      return this.isCard ? () => providerAddPaymentMethod(this.provider) : null;
    },

    CVCInfo() {
      return this.isCard
        ? {
          ...this.currentPaymentMethod,
          profile: this.profile,
        }
        : null;
    },

    isCard() {
      return isTypeCard(this.currentPaymentMethod);
    },

    normalizedPaymentMethods() {
      const formatPaymentMethod = (paymentMethodNumberType, paymentMethodNumber) => {
        const getPaymentMethodLabel = {
          [PAYMENT_TYPES.bankTransfer]: () => this.$t('common.payment_method.bank_transfer'),
          [PAYMENT_TYPES.iban]: () => maskIbanNumber(paymentMethodNumber),
        };

        return getPaymentMethodLabel[paymentMethodNumberType]?.() || maskCardNumber(paymentMethodNumber);
      };

      const mapCardDetails = paymentMethod => ({
        label: formatPaymentMethod(paymentMethod?.type, paymentMethod?.number),
        value: paymentMethod.uuid,
        icon: ICONS.cards[paymentMethodIcon(paymentMethod)],
      });

      return map(this.paymentMethodsCollection, mapCardDetails);
    },

    paymentMethodIconType() {
      return paymentMethodIcon(this.currentPaymentMethod);
    },
  },

  watch: {
    paymentMethodsCollection: {
      async handler(collection) {
        if (collection.length) {
          await this.getCurrentPaymentMethodUUID();
        }
      },
      deep: true,
      immediate: true,
    },

    async currentPaymentMethodUUID(newCurrentPaymentMethodUUID) {
      this.currentPaymentMethod = await this.getPaymentMethodByUUID(newCurrentPaymentMethodUUID, this.profile);
    },

    async showHelperComponent() {
      await this.getProviderHelperComponent();
    },

    isPaymentMethodsCollectionLoading: {
      async handler(loadingStatus) {
        this.showAddPaymentMethodOnSelect = !loadingStatus && this.isEmptyPaymentMethodsCollection(this.profile) && !this.isUnfoldAddEmbedded;

        if (!loadingStatus && this.paymentMethodsCollection.length) {
          const defaultPaymentMethodUuid = this.getDefaultPaymentMethodUUID(this.profile);
          this.currentPaymentMethod = await this.getPaymentMethodByUUID(defaultPaymentMethodUuid, this.profile);

          if ((this.selectLocked || this.selectInBackground) && !(this.currentPaymentMethod && defaultPaymentMethodUuid)) {
            logger.message('select locked or in background with no default payment method!', LOG_TYPE.warning);

            this.$emit('update:no-default-payment-method');
          } else {
            this.onSelectPaymentMethod();
          }
        }
      },
      deep: true,
      immediate: true,
    },

    forceUnmountCvc: {
      handler(status) {
        this.overrideCVCComponentFromProvider = status;
      },
      immediate: true,
    },
  },

  async created() {
    this.PAYMENT_TYPES = PAYMENT_TYPES;

    this.getProviderHelperComponent();
  },

  methods: {
    maskCardNumber,
    paymentMethodIcon,

    async getCurrentPaymentMethodUUID() {
      this.currentPaymentMethodUUID = await this.getDefaultPaymentMethodUUID(this.profile) || first(this.paymentMethodsCollection)?.uuid;
      this.selectedPaymentMethod = find(this.normalizedPaymentMethods, ['value', this.currentPaymentMethodUUID]);
    },

    onSelectPaymentMethod() {
      const isSameUuid = this.currentPaymentMethodUUID === this.currentPaymentMethod?.uuid;

      if (
        isNilValue(this.currentPaymentMethodUUID) || (isSameUuid && !(this.selectLocked || this.selectInBackground))
      ) {
        return;
      }

      this.isCVCReady = false;

      this.$emit('update:payment-method-selected', this.currentPaymentMethodUUID, this.isCard);
    },

    onClickAddPaymentMethod() {
      this.$emit('launch-add-payment-method');
    },

    onCVCReady() {
      this.isCVCReady = true;
    },

    onCVCCandidatePaymentMethod(CVCCandidatePaymentMethod) {
      this.isCVCValid = CVCCandidatePaymentMethod.isValid;
      this.$emit('update:cvc-candidate-payment-method', CVCCandidatePaymentMethod);
    },

    async getProviderHelperComponent() {
      const createdUtils = await providerUtils(this.provider);

      if (createdUtils) {
        const { providerHelperComponent } = createdUtils;

        this.helperComponent = providerHelperComponent;
      } else {
        this.helperComponent = null;
      }
    },
  },
};
</script>

<style lang="scss">
  .SelectPaymentMethod {
    min-height: 130px;

    &.hide-cvc,
    &.empty {
      min-height: auto;
    }

    .label-select-outside + .payment-method-select {
      label {
        display: none;
      }
    }

    .add-payment-wrapper {
      height: 40px;
    }

    .cvc-loader {
      display: block;
    }

    .add-button > button {
      text-transform: uppercase;
    }
  }
</style>
