import {
  ACTIONS, ADYEN_71_API_VERSION, ADYEN_VERSION_THRESHOLD, CHALLENGE_ENDPOINT_VERSION, ORIGIN, PAYMENT_TYPES, RESPONSE_STATUS, STAGE,
} from '../../constants/paymentMethodsHub';

import { providerUtils } from '../../paymentMethodsProviders/PaymentProviderComponents';

import { PaymentMethodsHubComposable } from '../PaymentMethodsHubComposable';

export const onChallenge = async (composablePayload, storeData, callbackFn) => {
  const { getProfileInfo, filterResponse } = PaymentMethodsHubComposable(storeData);

  const {
    originalResponse,
    parentActionCallback,
    contextData,
    setupProps,
    commonEmit,
  } = composablePayload;

  const { adyenVersion } = originalResponse;
  const AdyenVersionAboveThreshold = adyenVersion && (adyenVersion > ADYEN_VERSION_THRESHOLD);

  parentActionCallback('toggleHelperComponentLoader', false);

  parentActionCallback('setStage', STAGE.threeDS2Challenge);

  const utils = await providerUtils(setupProps.props.provider);

  const responseActionType = AdyenVersionAboveThreshold ? `${originalResponse.action.type}${originalResponse.action.subtype}` : JSON.parse(originalResponse.action).type;

  let customerData = getProfileInfo();

  const actionUsed = contextData.flowTracking.currentAction;
  const isActionPayment = actionUsed === ACTIONS.payment;

  let isFullIframe = isActionPayment;

  customerData = {
    ...customerData,
    type: PAYMENT_TYPES.card,
  };

  const actionConfiguration = {
    locale: setupProps.locale,
  };

  if (
    originalResponse.providerStatusRaw === RESPONSE_STATUS.challengeShopper && responseActionType.toUpperCase() === STAGE.threeDS2Challenge
  ) {
    parentActionCallback('toggleHelperComponent', true);

    let versionApplied = isActionPayment ? CHALLENGE_ENDPOINT_VERSION.payment : CHALLENGE_ENDPOINT_VERSION.add;

    if (AdyenVersionAboveThreshold) {
      versionApplied = ADYEN_71_API_VERSION;
      isFullIframe = true;
    }

    const recoveredMatchingName = originalResponse.matchingName;

    const authChallengeCallback = async (state, component, parentAction = parentActionCallback) => {
      parentAction('toggleHelperComponentLoader', true);

      try {
        parentAction('setStage', STAGE.submitting);

        const confirmChallengeResponse = await utils.handleOnAdditionalDetails(
          state,
          component,
          originalResponse,
          customerData,
          versionApplied,
          actionUsed,
        );

        const combinedResponse = {
          ...confirmChallengeResponse,
          matchingName: recoveredMatchingName,
          parentActionCallback,
          contextData,
          setupProps,
          commonEmit,
        };

        parentAction('toggleHelperComponent', false);

        return callbackFn(combinedResponse, ORIGIN.challenge);
      } catch (err) {
        return callbackFn(null, ORIGIN.forcedError, err.message);
      }
    };

    await parentActionCallback('$nextTick', null, 'async');

    const filteredResponse = filterResponse(originalResponse);

    await utils.onAdditionalDetails(actionConfiguration, filteredResponse, authChallengeCallback, isFullIframe);
  }
};
