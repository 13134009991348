import {
  COUNTRIES_ISO_CODES, ENV, LOG_TYPE, logger,
} from '@emobg/web-utils';
import config from '@/config';

import { storage } from './storage';

import { filterResponse, parseResponse } from './responseUtils';

import {
  ACTIONS, CUSTOMER_TYPE, DEFAULT_PAYMENT_PROVIDER, HOLDER_NAME_REDIRECT_MATCH, HUB_REDIRECT_FLOW_STATUS, PAYMENT_CHANNELS, PROVIDER_USED,
} from '../constants/paymentMethodsHub';
import { collectBrowserInfo } from './collectBrowserInfo';

import { providerAPI, providerUtils } from '../paymentMethodsProviders/PaymentProviderComponents';

export const PaymentMethodsHubComposable = storeData => {
  const currentSelectedProfile = storeData.profile;
  const currentUserData = storeData.userData;
  const currentCSOperator = storeData.CSOperator;
  const { profileCollection } = storeData;

  const oppositeProfile = profileCollection.find(({ type }) => type !== currentSelectedProfile.type);

  const profileUuid = currentSelectedProfile.uuid;
  const isBusinessProfile = currentSelectedProfile.type === CUSTOMER_TYPE.business;

  const countryCode = currentCSOperator.country_code || COUNTRIES_ISO_CODES.spain;

  const companyUuid = isBusinessProfile ? currentUserData.company.uuid : null;
  const userUuid = currentUserData.uuid;
  const isCompanyPays = isBusinessProfile ? currentUserData.company.company_pays : null;

  const userIsAdmin = currentUserData.roles.includes(CUSTOMER_TYPE.companyAdmin);

  const isBusinessInCompanyPays = isBusinessProfile && isCompanyPays;

  const isAdminInCompanyPays = userIsAdmin && isBusinessInCompanyPays;

  const getAPIFromProvider = async provider => {
    const getProviderAPI = providerUsed => {
      try {
        return providerAPI(providerUsed);
      } catch (error) {
        logger.message(error.message, LOG_TYPE.error);
        logger.message(`provider ${providerUsed} not found!`, LOG_TYPE.error);
        return null;
      }
    };

    const apiProviderUsed = provider || DEFAULT_PAYMENT_PROVIDER;

    const api = await getProviderAPI(apiProviderUsed);

    return api;
  };

  const getPaymentMethodPayload = (paymentMethodInfo, flowTracking) => {
    const { origin, pathname } = window.location;

    const isProd = config.data.environment === ENV.production;

    const { paymentMethod } = paymentMethodInfo;

    const holder = paymentMethodInfo?.holderName || paymentMethodInfo?.holder || paymentMethod?.holder || paymentMethod?.holderName;

    /**
     * to mimic a redirect card flow, Adyen states that it can be
     * mimic by passing a void origin
     * So, to get this use 'REDIRECT' as holder in the add card creation
     * **using a 3DS2Secure card (challenge or identify)**
     * (see https://docs.adyen.com/development-resources/testing/test-card-numbers)
     * Use this in envs other than prod
     */
    const isHolderNameRedirect = holder ? holder.toUpperCase() === HOLDER_NAME_REDIRECT_MATCH : null;

    const mimicRedirectFlow = isHolderNameRedirect && !isProd;

    const altOrigin = mimicRedirectFlow ? '' : origin;

    const returnUrl = `${origin + pathname}?${HUB_REDIRECT_FLOW_STATUS.fromRedirectQueryString}`;

    const commonPayload = {
      browserInfo: collectBrowserInfo(),
      return_url: returnUrl,
      origin: altOrigin,
      channel: PAYMENT_CHANNELS.web,
      countryCode,
    };

    if (flowTracking?.currentAction === ACTIONS.add) {
      return {
        ...commonPayload,
        paymentMethod: {
          ...paymentMethodInfo,
        },
        shopperReference: profileUuid,
        user_profile_uuid: profileUuid,
      };
    }

    const {
      isValid = false,
      paymentMethod: {
        profile,
        encryptedSecurityCode: securityCode,
        ...paymentMethodWithoutProfile
      },
    } = paymentMethodInfo;

    return {
      ...commonPayload,
      paymentMethod: {
        ...paymentMethodWithoutProfile,
        securityCode,
      },
      isValid,
      profile,
    };
  };

  const getProfileInfo = () => ({
    profileType: isBusinessInCompanyPays ? CUSTOMER_TYPE.company : CUSTOMER_TYPE.user,
    userUuid,
    companyUuid,
    profileUuid,
    isBusinessProfile,
    isBusinessInCompanyPays,
    isAdminInCompanyPays,
    userIsAdmin,
    currentCSOperator,
    oppositeProfile,
  });

  const addNewPaymentMethod = async (candidatePaymentMethod, flowTracking, provider) => {
    const payload = getPaymentMethodPayload(candidatePaymentMethod, flowTracking);

    const api = await getAPIFromProvider(provider);

    const { addCompanyNewPaymentMethod, addUserNewPaymentMethod } = api;

    return (isBusinessInCompanyPays)
      ? addCompanyNewPaymentMethod(companyUuid, payload)
      : addUserNewPaymentMethod(userUuid, payload);
  };

  const getPaymentMethodsCollection = async provider => {
    const api = await getAPIFromProvider(provider);

    const { getCompanyPaymentMethodsCollection, getUserPaymentMethodsCollection } = api;

    return (isBusinessInCompanyPays)
      ? await getCompanyPaymentMethodsCollection(companyUuid) || []
      : await getUserPaymentMethodsCollection(userUuid) || [];
  };

  const setDefaultPaymentMethod = async (paymentMethodUuid, provider, providedUserProfileUuid = profileUuid) => {
    const api = await getAPIFromProvider(provider);

    const { setCompanyDefaultPaymentMethod, setUserDefaultPaymentMethod } = api;

    try {
      if (isBusinessInCompanyPays) {
        await setCompanyDefaultPaymentMethod(companyUuid, paymentMethodUuid, userUuid);
      } else {
        await setUserDefaultPaymentMethod(providedUserProfileUuid, { user_payment_method_uuid: paymentMethodUuid });
      }
    } catch (error) {
      logger.message(error, LOG_TYPE.error);
    }
  };

  const removePaymentMethod = async (paymentMethodUuid, provider) => {
    let endPoint;
    let uuid;
    let payload;

    const api = await getAPIFromProvider(provider);

    const { removeCompanyPaymentMethod, removeUserPaymentMethod } = api;

    if (isBusinessInCompanyPays) {
      endPoint = removeCompanyPaymentMethod;
      uuid = companyUuid;
      payload = {
        company_payment_method_uuid: paymentMethodUuid,
        user_uuid: currentUserData.uuid,
      };
    } else {
      endPoint = removeUserPaymentMethod;
      uuid = currentUserData.uuid;
      payload = {
        user_payment_method_uuid: paymentMethodUuid,
      };
    }

    try {
      await endPoint(uuid, payload);
    } catch (error) {
      logger.message(error, LOG_TYPE.error);
      throw error;
    }
  };

  const getUtilsFromStoredProvider = async currentProvider => {
    const getProviderUtils = providerUsed => {
      try {
        return providerUtils(providerUsed);
      } catch (error) {
        logger.message(error.message, LOG_TYPE.error);
        logger.message(`provider ${providerUsed} not found!`, LOG_TYPE.error);
        storage.remove(PROVIDER_USED);
        return null;
      }
    };

    return getProviderUtils(currentProvider);
  };

  return {
    addNewPaymentMethod,
    getAPIFromProvider,
    getPaymentMethodsCollection,
    getPaymentMethodPayload,
    getProfileInfo,
    getUtilsFromStoredProvider,
    removePaymentMethod,
    setDefaultPaymentMethod,
    storage,
    filterResponse,
    parseResponse,
  };
};
