import { mapActions, mapGetters } from 'vuex';
import * as OperatorLocationsModule from '@/stores/OperatorLocations/OperatorLocationsModule';

export const {
  operatorLocations,
  oneWayLocations,
  fetchOperatorLocations,
  fetchLocationByOperatorUuid,
} = {

  ...mapGetters(OperatorLocationsModule.NAMESPACE, ['operatorLocations', 'oneWayLocations']),
  ...mapActions(OperatorLocationsModule.NAMESPACE, {
    fetchOperatorLocations: OperatorLocationsModule.ACTIONS.fetchOperatorLocations,
    fetchLocationByOperatorUuid: OperatorLocationsModule.ACTIONS.fetchLocationByOperatorUuid,
  }),
};
