import isString from 'lodash/isString';

export const storage = {
  add: (storageKey, storageObject) => {
    sessionStorage.setItem(storageKey, isString(storageObject) ? storageObject : JSON.stringify(storageObject));
  },

  get: storageKey => {
    try {
      return JSON.parse(sessionStorage.getItem(storageKey));
    } catch {
      return sessionStorage.getItem(storageKey);
    }
  },

  remove: storageKey => {
    sessionStorage.removeItem(storageKey);
  },
};
